/* Step.css */

.step.done {
  background-color: #c2edcf; /* Green background color for 'done' steps */
}

.icon-container {
  position: absolute;
  top: calc(45%);
  margin-left: 30px; 
}

.check-icon {
  color: green; /* Green color for checkmark icon */
  scale: 3
}
