/* ClassForm.css */
.class-form-container {
  max-width: 500px;
  margin: 50px auto; /* Padding from the top */
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow box effect */
  font-family: Arial, sans-serif; /* Set the font-family */
}

.form-header {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.class-form {
  display: flex;
  flex-direction: column;
}

.input-field,
textarea {
  margin-bottom: 10px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  font-family: Arial, sans-serif; /* Set the font-family */
}

.submit-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

/* ClassForm.css */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 18px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #f1f1f1;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #007bff;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox {
  margin-right: 10px;
}
