.App {
  text-align: center;
}

/* App.css */

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.step {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  width: 75%; /* Set width to 75% of the screen */
  background-color: #f0f8ff; /* Very light blue background color */
}

.step h2 {
  color: #333;
}

.step p {
  color: #666;
}
